import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const TermsAndConditions = () => {


  useEffect(() => {
   navigate('/');
}, [])
   return (
      <>
      <div className="text">Redirecting</div>
      </>
   );
   }



   export default TermsAndConditions
